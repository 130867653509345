<template>
	<div class="page">
		<van-nav-bar :title="this.xuanfeidata.xuanfei_name" class="nav-bar newnavbar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<!-- <div class="box">
			<p class="name">{{ this.xuanfeidata.xuanfei_name }}</p>
			<p class="title">{{ this.xuanfeidata.vo_title }}</p>
			<van-image width="98%" fit="contain" height="100%" v-for="(v, k) in xuanfeidata.img_url" :key="k" :src="v" />
			<van-button round="true" @click="yuyue" class="button"
				color="linear-gradient(to right, #7f5778 , #e5c2a0)">phòng đặt chỗ</van-button>
		</div> -->
		<div class="feiziInfo">
			<div class="topInfo">
				<div>
					<span>{{ xuanfeidata.xuanfei_name }}</span>
					<!-- <span>{{ xuanfeidata.bust }}</span> -->
				</div>
				<div>{{ $t('personHeight') }}: {{ xuanfeidata.height }}{{ $t('bust') }}：{{ xuanfeidata.bust }}</div>
				<div>
					<div v-for="v in (xuanfeidata.flag || '').split('，')" :key="v">{{ v }}</div>
				</div>
				<!-- <div v-if="$i18n.locale == 'zh'">
					<div v-for="v in ((xuanfeidata.flag || '').split('|')[0]).split('，')" :key="v">{{ v }}</div>
				</div>
				<div v-if="$i18n.locale == 'en'">
					<div v-for="v in ((xuanfeidata.flag || '').split('|')[1]).split('，')" :key="v">{{ v }}</div>
				</div>
				<div v-if="$i18n.locale == 'ja'">
					<div v-for="v in ((xuanfeidata.flag || '').split('|')[2]).split('，')" :key="v">{{ v }}</div>
				</div> -->
				<div style="display: flex;">
					<span style="color: #6b22b3;font-size: 14px;">{{ $t('tollRange') }}：</span>
					<van-rate style="display: flex;" v-model="xuanfeidata.number" color="#f2b247" void-color="#fff"
						readonly size="15" />
				</div>
				<div style="color: #6b22b3;font-size: 14px;margin: 4px 0;">
					{{ $t('residence') }}：{{ xuanfeidata.address }}
					<!-- {{ $i18n.locale == 'zh' ? (xuanfeidata.address || '').split(',')[0] : $i18n.locale == 'en' ? (xuanfeidata.address || '').split(',')[1] : $i18n.locale == 'ja' ? (xuanfeidata.address || '').split(',')[2] : '' }} -->
				</div>
				<!-- <div style="color: #6b22b3;font-size: 14px;">Trên không：không</div> -->
				<div class="yuyueBox" @click="yuyue">{{ $t('reservationNow') }}</div>
				<div class="dianzanimg" @click="dzFn">
					<van-image fit="cover" width="60" height="60" src="/img/zan.c28032c8.png" />
					<p style="color: #6b22b3;margin: 0;">{{ dianzannum }}</p>
				</div>
			</div>
			<div class="cenInfo">
				<div style="width: 50%;">
					<van-tabs v-model="active" :line-width="50" title-active-color="#ca3358"
						title-inactive-color="#3634d9" @change="changeTab">
						<van-tab :title="$t('photo')" name="0"></van-tab>
						<van-tab :title="$t('video')" name="1"></van-tab>
					</van-tabs>
				</div>
				<div v-if="active == 0">
					<div class="imgswiper">
						<div style="width: 100px; height: 100px; margin-right: 10px;"
							v-for="(v, i) in xuanfeidata.img_url" :key="i" @click="choseedImg(v, i)">
							<van-image fit="cover" width="100" height="100" class="imgI" :class="{ active: imgI == i }"
								:src="v" />
						</div>
					</div>
					<van-image fit="cover" width="100%" height="635" :src="choseImg" @click="ImagePreview" />
				</div>
				<div v-show="active == 1" style="padding-top: 20px;">
					<video id="my-video2" playsinline='false' webkit-playsinline='false' class="video-js">
					</video>
				</div>
			</div>
			<div class="btmInfo">
				<div style="color: #2a1468;margin-bottom: 10px;font-weight: bold;">{{ $t('personalProfile') }}</div>
				<div style="color: #534589;">{{ xuanfeidata.jianjie }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ImagePreview } from 'vant';
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
	data() {
		return {
			xuanfeidata: {},
			ratevalue: 10,
			active: 0,
			imgI: 1,
			choseImg: '',
			player: null,
			dianzannum: 0,
		};
	},
	methods: {
		back() {
			// this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
			this.$router.go(-1);
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				console.log("this.xuanfeidata", res.data);
				this.xuanfeidata = res.data;
				this.dianzannum = this.xuanfeidata.dianzan;
				this.choseImg = this.xuanfeidata.img_url[0];
			});
		},
		yuyue() {
			this.$http({
				method: 'get',
				url: 'user_get_game_list'
			}).then(res => {
				if (res.code === 200) {
					if (res.data && res.data.length) {
						this.$toast(this.$t('appointmentSuccessful'));
					} else {
						this.$toast(this.$t('completeActivationTask'));
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})
		},
		ImagePreview() {
			ImagePreview([this.choseImg])
		},
		choseedImg(v, i) {
			this.imgI = i;
			this.choseImg = v;
		},
		changeTab(v) {
			if (v == 1) {
				let that = this;
				this.$nextTick(() => {
					that.player = videojs("my-video2", {
						height: "auto",
						preload: "auto", // 预加载
						controls: true,  // 显示播放的控件
						multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
					}, function onPlayerReady() { });

					that.player.src([{
						src: that.xuanfeidata.pvideo,
						// type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
					}]);
				});
			} else {
				this.player = null;
			}
		},
		dzFn() {
			console.log("dianzan", this.xuanfeidata.dianzan)
			if (this.xuanfeidata.dianzan == this.dianzannum) {
				this.dianzannum++;
			} else if (this.xuanfeidata.dianzan < this.dianzannum) {
				this.dianzannum--;
			}
		},
	},
	created() {
		this.getxuanfeidata();
	},
	destroyed() {
		this.player && this.player.dispose();
	}
};
</script>

<style lang="less" scoped>
::v-deep .vjs-big-play-button {
	// top: 50%;
	// left: 50%;
	// margin-top: -50px;
	// margin-left: -100px;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	width: 120px !important;
	height: 80px !important;
	line-height: 80px !important;
	font-size: 50px !important;
}

.video-js {
	width: 100%;
	// height: 420px;
	font-size: 24px;
}

.feiziInfo {
	background: #ebedf0;

	>div {
		padding: 30px 20px;
		background: #fff;
		margin-bottom: 30px;
	}

	.topInfo {
		position: relative;

		>div:nth-child(1) {
			font-weight: 600;

			>span:nth-child(1) {
				color: #2c1868;
				font-size: 36px;
			}

			>span:nth-child(2) {
				color: #f2b247;
				font-size: 36px;
			}
		}

		>div:nth-child(2) {
			color: #6b22b3;
			font-size: 24px;
			margin: 10px 0;
		}

		>div:nth-child(3) {
			width: 50%;

			>div {
				background: linear-gradient(50deg, #9b54ca, #e6557f);
				color: #fff;
				display: inline-block;
				border-radius: 10px;
				margin-right: 15px;
				font-size: 12px;
				padding: 8px;
				margin-bottom: 15px;
			}

			>div:nth-child(5) {
				margin-right: 120px;
			}
		}

		.yuyueBox {
			position: absolute;
			right: 30px;
			bottom: 20px;
			color: #fff;
			padding: 10px 15px;
			background: linear-gradient(20deg, #e73266, #ee5380);
			border-radius: 50px;
		}

		.dianzanimg {
			position: absolute;
			top: 30px;
			right: 50px;
			text-align: center;
		}
	}

	.cenInfo {
		.van-tab__text {
			font-size: 18px;
		}

		.imgswiper {
			width: 100%;
			overflow-x: scroll;
			margin: 30px 0;
			display: flex;
			flex-wrap: nowrap;

			>div {
				.imgI.active {
					border: 5px solid #e54878;
				}


			}
		}

	}
}

.container {
	display: inline-block;
}

.newnavbar {
	height: 100px;
	background: linear-gradient(30deg, #7d76ef, #d63f8c) !important;
}

.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom: 6.25rem;
}

.name {
	font-size: 1.125rem;
}

.title {
	font-size: 0.625rem;
}

.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}
</style>
