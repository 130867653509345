import Vue from "vue";
import App from "./App.vue";
import Vant from "vant";
import "vant/lib/index.css";
import router from "./router";
import http from "./http";
import config from "./http/config";
import VueAwesomeSwiper from "vue-awesome-swiper";
import common from "./common/function";
import "swiper/swiper-bundle.css";
import store from "./store";

import "video.js/dist/video-js.css";

// 引入插件和语言包
import VueI18n from "vue-i18n";
// import zh from "@/i18n/langs/zh";
// import en from "@/i18n/langs/en";
// import ko from "@/i18n/langs/ja";
import ko from "@/i18n/langs/ko";
Vue.use(VueI18n);

// 自动设置多语言 默认选择浏览器系统语言
const navLang = navigator.language; // 判断当前浏览器使用的语言
let localLang = navLang
  ? navLang.indexOf("-") != -1
    ? navLang.split("-")[0]
    : navLang
  : "";
let lang = localStorage.getItem("language") || "ja" || localLang; // 如果有缓存，取缓存的语言
const i18n = new VueI18n({
  // locale: lang,
  locale: "ko",
  messages: {
    // zh: zh, // 中文语言包
    // en: en, // 英文语言包
    // ja: ja, // 日语语言包
    ko: ko, // 韩语语言包
  },
  silentTranslationWarn: true,
});
localStorage.setItem("language", lang); //最后缓存当前使用的语言

Vue.prototype.$http = http;
Vue.prototype.$config = config;
Vue.prototype.common = common;
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(Vant);
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
