<template>
  <div class="container page lottery">
    <!-- $i18n.locale == 'zh' ? (lottery.name || '').split(',')[0] : $i18n.locale == 'en' ? (lottery.name || '').split(',')[1] : $i18n.locale == 'ja' ? (lottery.name || '').split(',')[2] : '' -->
    <van-nav-bar :title="lottery.name" class="nav-bar">
      <!-- <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template> -->
      <template #right>
        <div class="right" @click="showLeftPop = true">{{ $t('switchWindows') }}</div>
      </template>
    </van-nav-bar>
    <div class="record">
      <div class="period">
        <van-image class="cover" :src="this.lottery.ico" width="52" height="52">
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
          <template v-slot:error><van-image src="/img/error.png" /></template>
        </van-image>
        <!-- <span class="period-number">{{this.lottery.now_expect}}</span> -->
        <span class="period-number" style="color: #fff; font-size: 14px;">{{ this.lottery.next_expect }}{{
      $t('designatedTime') }}</span>
        <div class="next-number">
          <!-- <span>{{this.lottery.next_expect}}</span> -->
          <van-count-down :time="time" @finish="check()" />
        </div>

      </div>
      <div class="numberBox"
        style="background: #f47cd5; padding: 12px; margin-bottom: 10px; border-radius: 8px; display: flex; align-items: center;">
        <div
          style="border-right: 4px solid #f9b1e7; padding: 0 30px 0 10px; color: #fff; font-weight: bold; font-size: 16px; text-align: center;">
          <div>{{ lottery.now_expect }}</div>
          <div>{{ $t('votingResults') }}</div>
        </div>
        <div class="resBox">
          <div class="resItem">{{ sum }}</div>
          <div>
            <div class="ressquareBox" style="margin: 0 0 5px 0; min-width: 70px;">{{ size }}</div>
            <div class="ressquareBox" style="margin: 0;">{{ double }}</div>
          </div>
          <van-icon style="margin-left: auto;" name="arrow-down" :class="{ up: active, down: !active }"
            @click="active ? active = false : active = true" />
        </div>
      </div>
      <!-- <div class="linear-gradient" style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));"></div> -->
      <!-- <div class="recent">
          <div class="kuaisan-ball left">
            <van-image class="res-img" :src="this.shanzi_1">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <van-image class="res-img" :src="this.shanzi_2">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <van-image class="res-img" :src="this.shanzi_3">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <span class="res-des middle">{{this.sum}}</span>
            <span class="res-des middle" style="margin-right: 10px;">{{this.size}}</span>
            <span class="res-des middle">{{this.double}}</span>
          </div>
          <van-icon name="arrow-down" :class="{ up: active,down:!active }" @click="active ? active = false : active = true" />
        </div> -->
    </div>
    <div class="history_popup"></div>
    <div class="wrapper">
      <div class="options-bar">
        <div class="game">
          <!-- <div class="tips">
                <p class="odds">【{{this.lottery.desc}}】</p>
                <div class="play-tip" >
                  <van-icon name="more-o" />
                  <span class="span-text" @click="$router.push({path:'/GameRecord'});">hồ sơ nhiệm vụ</span>
                  <van-popup  class="mask" get-container="body" v-model="playgame">
                      <div class="play-type-tip">
                        <div class="title">luật chơi</div>
                        <div class="wrapper">
                          <div class="item">
                              <van-icon name="info-o" />
                              <div class="content">
                                <p class="content-title">Mẹo trò chơi</p>
                                <p class="content-detail">Chọn số từ các hình thức tùy chọn và giá trị để đặt cược</p>
                              </div>
                          </div>
                          <div class="item">
                            <van-icon name="comment-o" />
                            <div class="content">
                              <p class="content-title">mô tả chiến thắng</p>
                              <p class="content-detail">Tổng ba số 11~18 là Phong, tổng 3~10 là Hòa;</p>
                            </div>
                          </div>
                          <div class="item">
                            <van-icon name="description" />
                            <div class="content">
                              <p class="content-title">ví dụ cá cược</p>
                              <p class="content-detail">Hình thức cược: Hòa Lô số: 123, Hòa thắng</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </van-popup>
                </div>
              </div> -->
          <div class="linear-gradient"
            style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));">
          </div>
          <div class="sumValueTwoSides">
            <div class="rectangle large" :class="{ active: choose[v.type] }" v-for="(v, key) in lottery_peilv_list"
              :key="key" @click="choosePlay(v.type, v.name);">
              <!-- <p class="name-text large">{{ v.name }}</p> -->
              <p class="name-text large">{{ v.name === 'big' ? $t('big') : v.name === 'small' ? $t('small') : v.name ===
      'one' ? $t('one') : v.name === 'two' ? $t('two') : '' }}</p>
              <!-- <p class="odd-text large">{{ v.proportion }}</p> -->
            </div>
          </div>
          <!-- <div style="padding: 0 20px 20px;">
                <div style="background: linear-gradient(30deg, #7d76ef, #d63f8c) !important; text-align: center; padding: 15px 0; font-weight: bold; font-size: 16px;">Phát sóng kích hoạt thời gian thực</div>
                <div class="b_list">
                  <div class="b_scroll">
                    <div class="b_item" v-for="(v, i) in game_getuserenable_list" :key="i">
                      <span>Chúc mừng: {{ v }}</span>
                      <span>Hoàn tất kích hoạt</span>
                    </div>
                  </div>
                </div>
              </div> -->
        </div>
      </div>
      <div class="bottom-bar">
        <div class="bar">
          <div class="left">
            <div class="item" @click="shopping ? shopping = false : shopping = true">
              <van-icon name="cart-o" class="jixuanico" />
              <span class="text"></span>
            </div>
            <div class="line"></div>
          </div>
          <div class="mid">
            <span class="text">{{ $t('availablePoints') }}：</span>
            <span class="text num">{{ this.userInfo.money }}</span>
            <!-- <span class="text">cá nhân</span> -->
          </div>
          <div class="right" style="font-size: 14px; border-radius: 8px;" @click="jiesuan()">{{ $t('submitTo') }}</div>
        </div>
        <div class="wrapper" style="height: 230px;" :class="{ active: shopping }">
          <div class="item">
            <span class="label" style="color: #3b3597;">{{ $t('currentSelectedNumber') }}：</span>
            <div class="bet-number">{{ this.shopchoose }}</div>
            <van-icon name="arrow-down" :class="{ up: !shopping, down: shopping }"
              @click="shopping ? shopping = false : shopping = true" />
          </div>
          <div class="item">
            <span class="label" style="color: #3b3597;">{{ $t('pointsPerOrder') }}：</span>
            <div class="amount-wrapper">
              <van-field v-model="money" type="digit" placeholder="" style="background: #f2f2f5;  width: 210px;" />
              <!-- <span class="label">cá nhân</span> -->
            </div>
          </div>
          <div style="border-top: 1px solid #f7f8f9; padding-top: 15px; margin-top: 15px;">
            <div class="totalBox">
              <span>{{ $t('inTotal') }}</span>
              <span style="color: #ed50b2;">{{ formData.length }}</span>
              <span>{{ $t('one') }}</span>
              <span>{{ $t('amountMoney') }}</span>
              <span style="color: #ed50b2;">{{ formData.length * this.money }}</span>
              <span>{{ $t('integral') }}</span>
            </div>
            <div class="jifenItemBox">
              <div :class="{ active: money == 20 }" @click="money = 20">20</div>
              <div :class="{ active: money == 100 }" @click="money = 100">100</div>
              <div :class="{ active: money == 500 }" @click="money = 500">500</div>
              <div :class="{ active: money == 1000 }" @click="money = 1000">1K</div>
              <div :class="{ active: money == 2500 }" @click="money = 2500">2.5K</div>
              <div :class="{ active: money == 5000 }" @click="money = 5000">5K</div>
            </div>
          </div>
          <!-- <div class="item">
                <div class="part">
                  <span>tổng cộng</span>
                  <span class="number">{{this.formData.length}}</span>
                  <span>Ghi chú</span>
                </div>
                <div class="part">
                  <span>tổng cộng</span>
                  <span class="number">{{this.formData.length * this.money}}</span>
                  <span>cá nhân</span>
                </div>
              </div> -->
        </div>
      </div>
      <van-popup v-model="jiesuanpage" get-container="body">
        <div class="confirm-order-modal">
          <div class="head van-hairline--bottom">
            <p class="text">{{ $t('taskList') }}</p>
          </div>
          <ui class="list">
            <li v-for="(v, key) in formData" :key="key" class="lise-item van-hairline--bottom">
              <div class="main">
                <p class="bet-name">{{ v.name === 'big' ? $t('big') : v.name === 'small' ? $t('small') : v.name ===
      'one' ? $t('one') : v.name === 'two' ? $t('two') : '' }}</p>
                <p class="detail-text">1{{ $t('pour') }} X {{ money }}{{ $t('individual') }} = {{ money }}{{
      $t('individual') }}</p>
              </div>
              <van-icon @click="clearChooes(v.type)" name="close" />
            </li>
          </ui>
          <div class="sub-bar">
            <van-button class="item cancel-btn" type="default" @click="allClear()">{{ $t('clearOrder') }}</van-button>
            <van-button class="item sub-btn" type="default" @click="doSub">{{ $t('confirmSub') }}</van-button>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="active" position="top" :style="{ height: '70%' }">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="wrapper">
            <div class="item">
              <div class="left font-weight">{{ $t('ISSUE') }}</div>
              <div class="right font-weight">{{ $t('data') }}</div>
            </div>
            <div class="item" v-for="(v, key) in lottery_list" :key="key">
              <div class="left font-weight">{{ v.expect }}</div>
              <div class="right font-weight">
                <div class="kuaisan-ball left">
                  <!-- <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[0] + '.png'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image>
                          <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[1] + '.png'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image>
                          <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[2] + '.png'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image> -->
                  <span class="ballBox">{{ v.opencode[0] }}</span>
                  <span class="ballBox">{{ v.opencode[1] }}</span>
                  <span class="ballBox" style="margin-right: 20px;">{{ v.opencode[2] }}</span>
                  <span class="resgardenBox">{{ v.opencode[0] + v.opencode[1] + v.opencode[2] }}</span>
                  <span class="ressquareBox">{{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) > 10 ? $t('big') :
      $t('small')
                    }}</span>
                  <span class="ressquareBox">{{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t('two') :
      $t('one')
                    }}</span>
                  <!-- <span class="res-des middle"></span> -->
                  <!-- <span class="res-des middle" style="margin-right: 10px;"></span> -->
                  <!-- <span class="res-des middle"></span> -->
                </div>
              </div>
            </div>
          </div>
        </van-pull-refresh>
      </van-popup>
    </div>

    <van-popup v-model="showLeftPop" position="right" :style="{ width: '70%', height: '100%' }">
      <div class="LeftPopBox">
        <div>
          <div>{{ $t('artilleryEngagement') }}</div>
          <div>
            <div v-for="(v, i) in gameitemList" :key="i" @click="leftPopClick(v.key)">
              <van-image :src="$config.baseURL + v.ico" :height="86" width="100%" :radius="8" fit="cover">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error><van-image src="/img/error.png" /></template>
              </van-image>
              <div>
                <!-- {{ $i18n.locale == 'zh' ? (v.name || '').split(',')[0] : $i18n.locale == 'en' ? (v.name || '').split(',')[1] : $i18n.locale == 'ja' ? (v.name || '').split(',')[2] : '' }} -->
                {{ v.name }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div>
          <div>空降活动</div>
          <div>
            <div v-for="i of 1" :key="i" @click="leftPopClick">
              <van-image :src="'https://xf.qdyy888.com/meizi/71/photo_2022-07-19_15-50-12.jpg'" :height="86" width="100%"
                :radius="8" fit="cover">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error><van-image src="/img/error.png" /></template>
              </van-image>
              <div>东城西就</div>
            </div>
          </div>
        </div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
var time;
var count = 0;
export default {
  data() {
    return {
      showLeftPop: false,
      jiesuanpage: false,
      choose: {},
      playgame: false,
      shopping: false,
      isLoading: false,
      play: {},
      lottery_peilv_list: {},
      lottery_list: {},
      active: false,
      userInfo: {},
      lottery: {},
      shanzi_1: "0",
      shanzi_2: "0",
      shanzi_3: "0",
      sum: 0,
      size: "",
      double: "",
      time: 0,
      shopchoose: "",
      gameitem: "",
      formData: [],
      money: "",
      game_getuserenable_list: [],

      gameitemList: [],
    };
  },
  methods: {
    leftPopClick(key) {
      this.showLeftPop = false;
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.getUserInfo();
        this.getLotteryInfo(key);
        this.getLotteryList(key);
      }
    },
    back() {
      return window.history.back();
    },
    doSub() {
      if (this.money === "0") {
        this.$toast(this.$t('integralError'));
        return false;
      }
      if (this.formData.length === 0) {
        this.$toast(this.$t('selectNumber'));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t('fillInPoints'));
        return false;
      } else {
        if (this.userInfo.money - (this.money * this.formData.length) < 0) {
          this.$toast(this.$t('insufficientPoints'));
          return false;
        } else {
          console.log("game_place_order_data", {
            item: this.gameitem,
            money: this.money,
            lid: this.lottery.id,
            mid: this.userInfo.id,
            expect: this.lottery.now_expect
          })
          this.$http({
            method: 'post',
            data: {
              item: this.gameitem,
              money: this.money,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect
            },
            url: 'game_place_order'
          }).then(res => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.allClear();
              this.getUserInfo();
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
          })
          return true;
        }
      }
    },
    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]['type']] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = "";
      this.gameitem = "";
      this.shopping = false;
      this.jiesuanpage = false;
    },
    jiesuan() {
      if (this.formData.length === 0) {
        this.$toast(this.$t('selectNumber'));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t('fillInPoints'));
        return false;
      }
      else {
        this.jiesuanpage ? this.jiesuanpage = false : this.jiesuanpage = true;
      }

    },
    clearChooes(type) {
      for (var i = 0; i < this.formData.length; i++) {
        if (type === this.formData[i]['type']) {
          this.formData.splice(i, 1)
          this.choose[type] = false;
        }
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.$t(this.formData[j]['name']);
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.$t(this.formData[j]['name']);
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = "";
        this.gameitem = "";
        this.shopping = false;
      }
      if (this.formData.length === 0) {
        this.jiesuanpage = false;
      }
    },
    choosePlay(type, name) {
      console.log("type", type)
      console.log("name", name)
      console.log("name", this.choose)
      if (this.choose[type] === true) {
        this.choose[type] = false;
        for (var i = 0; i < this.formData.length; i++) {
          if (type === this.formData[i]['type']) {
            this.formData.splice(i, 1)
          }
        }
      } else if (this.choose[type] === false) {
        this.formData.push({ 'name': name, 'type': type })
        this.choose[type] = true;
      }
      if (this.formData.length === 1) {
        this.shopping = true;
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.$t(this.formData[j]['name']);
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.$t(this.formData[j]['name']);
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = "";
        this.gameitem = "";
        this.shopping = false;
      }

    },
    check() {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        time = window.setInterval(() => {
          setTimeout(() => {
            this.getUserInfo();
            this.getLotteryInfo();
            this.getLotteryList();
            count++;
            if (count > 5) {
              clearInterval(time);
              count = 0;
            }
          }, 0)
        }, 300)
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('refreshSuccessful'));
        this.getLotteryList();
        this.isLoading = false;
      }, 200);
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryPeilv() {
      this.$http({
        method: 'get',
        data: { id: this.$route.query.id },
        url: 'lottery_get_peilv'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_peilv_list = res.data;
          console.log("lottery_peilv_list", this.lottery_peilv_list)
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryList(key) {
      this.$http({
        method: 'get',
        data: { key: key || this.$route.query.key },
        url: 'lottery_get_one_list'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_list = res.data;
          this.getLotteryPeilv();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryInfo(key) {
      this.$http({
        method: 'get',
        data: { key: key || this.$route.query.key },
        url: 'lottery_get_info'
      }).then(res => {
        if (res.code === 200) {
          if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
            this.$toast(this.$t('contactCollect'));
            this.$router.push({ path: '/Home' })
            return false;
          }
          this.lottery = res.data;
          this.time = res.data.second * 1000;

          if (this.time / 1000 === 59) {
            this.$toast(this.$t('successfulLotteryDraw') + this.lottery.now_expect);
          }
          this.shanzi_1 = "img/lottery/shaizi/" + res.data.opencode[0] + ".png";
          this.shanzi_2 = "img/lottery/shaizi/" + res.data.opencode[1] + ".png";
          this.shanzi_3 = "img/lottery/shaizi/" + res.data.opencode[2] + ".png";
          this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
          if (this.sum >= 11 && this.sum <= 18) {
            this.size = this.$t('big');
          } else if (this.sum >= 3 && this.sum <= 10) {
            this.size = this.$t('small');
          }
          if (this.sum % 2 === 0) {
            this.double = this.$t('two');
          } else {
            this.double = this.$t('one');
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })

    }
  },
  created() {
    // this.choose[this.$t('big')] = false;
    // this.choose[this.$t('small')] = false;
    // this.choose[this.$t('one')] = false;
    // this.choose[this.$t('two')] = false;
    this.choose['big'] = false;
    this.choose['small'] = false;
    this.choose['one'] = false;
    this.choose['two'] = false;

    this.choose['3'] = false;
    this.choose['4'] = false;
    this.choose['5'] = false;
    this.choose['6'] = false;
    this.choose['7'] = false;
    this.choose['8'] = false;
    this.choose['9'] = false;
    this.choose['10'] = false;
    this.choose['11'] = false;
    this.choose['12'] = false;
    this.choose['13'] = false;
    this.choose['14'] = false;
    this.choose['15'] = false;
    this.choose['16'] = false;
    this.choose['17'] = false;
    this.choose['18'] = false;
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
      this.getLotteryInfo();
      this.getLotteryList();

      // this.$http({
      //   method: 'post',
      //   data: {},
      //   url: 'game_getuserenable'
      // }).then(res => {
      //   if (res.code === 200) {
      //     this.game_getuserenable_list = res.data || [];
      //   } else if (res.code === 401) {
      //     this.$toast(res.msg);
      //   }
      // })

      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameitemList = res.data;
      })

    }
  },
  destroyed() {
    clearInterval(time);
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.totalBox {
  font-size: 28px;
  color: #3d359c;

  span {
    margin-right: 5px;
  }
}

.jifenItemBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 30px 0 0;

  >div {
    color: #a063ee;
    width: 130px;
    height: 90px;
    line-height: 80px;
    text-align: center;
    background: url('/img/hua.png') no-repeat center;
    background-size: contain;
    font-size: 28px;
  }

  >div.active {
    color: #fff;
    background: url('/img/hua_H.png') no-repeat center;
    background-size: contain;
  }
}

.ballBox {
  color: #d75db4;
  font-weight: bold;
  margin-right: 8px;
  font-size: 36px;
}

.resgardenBox {
  background: linear-gradient(90deg, #f560cd, #4f2b8a);
  color: #fff;
  width: 52px;
  height: 52px;
  line-height: 52px;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-right: 15px;
}

.ressquareBox {
  background: linear-gradient(90deg, #f560cd, #4f2b8a);
  color: #fff;
  height: 52px;
  line-height: 52px;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-right: 15px;
  padding: 0 8px;
}

.resBox {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  height: 100%;
  margin-left: 10px;
  width: calc(100% - 280px);
  box-sizing: border-box;

  .resItem {
    width: 64px;
    height: 64px;
    line-height: 64px;
    margin-right: 10px;
    background: linear-gradient(90deg, #8b73e6, #d75db4);
    border-radius: 50%;
    color: #fff;
    text-align: center;
  }
}

.lottery {
  background: url('/img/lotterybg.png') no-repeat top center;
  background-size: contain;

  .van-nav-bar {
    background: linear-gradient(90deg, rgba(223, 53, 173, .8), rgba(79, 29, 181, .8));
  }
}

.LeftPopBox {
  padding: 30px;

  >div {
    background: #e4e4e7;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;

    >div:first-child {
      font-weight: bold;
      margin-bottom: 30px;
    }

    >div:last-child {
      display: flex;
      flex-wrap: wrap;

      >div {
        width: 50%;
        padding: 0 20px 30px;
        text-align: center;
        font-size: 24px;
      }
    }
  }
}

.nav-bar .right {
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  font-size: 28px;
  // border-radius: 10px;
  // border: 2px solid #fff;
  line-height: 60px;
}

.record {
  padding-left: 20px;
  padding-right: 20px;
  // background-color: #fff;
  box-shadow: 0 2px 2px 0 #cacaca;
  z-index: 1;
  background: linear-gradient(90deg, rgba(223, 53, 173, .8), rgba(79, 29, 181, .8));
}

.record .period {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.record .period .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.record .period .period-number {
  flex: 1;
  margin-left: 20px;
  margin-right: 10px;
  height: 50px;
  line-height: 50px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.van-count-down {
  color: #f4d70a;
  font-size: 45px;
  margin-top: 10px;
  float: right;
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.record .recent {
  display: flex;
  align-items: center;
  height: 110px;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}

.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35px;
}

.van-icon {
  font-size: 40px;
}

.down {
  transition: all .5s;
}

.up {
  transform: rotate(180deg);
  transition: all .5s;
}

.wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 80px);
  background: #f2f3f5;
}

.options-bar .game {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.options-bar .game .tips {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 20px;
  background: linear-gradient(90deg, rgba(223, 53, 173, .8), rgba(79, 29, 181, .8));
}

.options-bar .game .tips .odds {
  flex: 1;
  font-size: 35px;
  font-weight: 500;
  color: #ff253f;
}

.options-bar .game .tips .play-tip {
  display: flex;
  align-items: center;
  height: 100%;
}

::v-deep .van-icon-more-o {
  color: #ff253f;
  font-size: 50px;
}

.options-bar .game .tips .play-tip .span-text {
  margin-left: 10px;
  font-size: 35px;
  font-weight: bolder;
  color: #ff253f;
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.sumValueTwoSides {
  display: flex;
  padding: 30px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;


  >div {
    width: calc(50% - 50px) !important;
    height: 160px !important;
    line-height: 160px;
    text-align: center;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .wrapper,
    .content {
      height: 160px !important;
    }

    p {
      margin: 0;
    }
  }

  .rectangle.active {
    background: #d07ac7;
    color: #fff;
  }
}

.rectangle {
  overflow: hidden;
}

.rectangle.large {
  margin: 0 0 30px 4%;
  width: 20%;
  border-radius: 10px;
}

.rectangle .wrapper {
  position: relative;
  padding: 0 10px;
  background: #fff;
}

.rectangle .wrapper .content {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rectangle.large .wrapper {
  padding-bottom: 100%;
}

.rectangle .wrapper .content .name-text.large {
  font-size: 45px;
}

.rectangle .wrapper .content .name-text {
  color: #7d7c7c;
  font-weight: bolder;
}

.rectangle .wrapper .content .odd-text.large {
  font-size: 25px;
  margin-top: -30px;
}

.rectangle .wrapper .content .odd-text {
  text-align: center;
  color: #ff253f;
}

.bottom-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 110px;
  height: 100px;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 #cacaca;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar .left .item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 20px;
}

.bottom-bar .bar .left .item .text {
  font-size: 22px;
  color: #7d7c7c;
}

.jixuanico {
  font-size: 45px;
}

.bottom-bar .bar .left .line {
  width: 2px;
  height: 50px;
  background: #dadada;
}

.bottom-bar .bar .mid {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bottom-bar .bar .mid .text {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

.bottom-bar .bar .mid .text.num {
  margin: 0 5px;
  color: #ff253f;
}

.bottom-bar .bar .right {
  padding: 0 30px;
  margin: 0 30px;
  color: #fff;
  // background: linear-gradient(
  //     270deg,#e6c3a1,#7e5678);
  background: linear-gradient(20deg, #8673e5, #d958a4);
  font-size: 40px;
  font-weight: 500;
  height: 70px;
  line-height: 70px;
  border-radius: 50px;
}

.rectangle.active .wrapper {
  background-color: #ff253f !important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-popup {
  position: absolute;
}

::v-deep .van-overlay {
  position: absolute;
  background-color: rgb(70 67 67 / 70%);
}

::v-deep .van-popup--top {
  top: -1px;
}

.wrapper .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.wrapper .item .left {
  width: 30%;
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.font-weight {
  font-weight: 700 !important;
}

.wrapper .item .right {
  flex: 1;
  display: flex;
  font-size: 30px;
  justify-content: center;
  overflow: hidden;
  color: #000;
}

.wrapper .item .kuaisan-ball .left {
  justify-content: flex-start;
}

.wrapper .item .kuaisan-ball {
  margin-left: 20px;
  flex: 1;
  display: flex;
  align-items: center;
}

.wrapper .item .kuaisan-ball .res-img {
  width: 50px;
  height: 50px;
  margin-right: 0;
}

.wrapper .item .kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.wrapper .item .kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 24px;
}

.play-type-tip {
  position: unset;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 650px;
  height: 700px;
  max-height: 50%;
  z-index: 10;
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
}

.play-type-tip .title {
  line-height: 90px;
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-weight: 500;
}

.mask {
  background-color: rgb(0 0 0 / 0%);
  animation-duration: 0.35s;
}

.play-type-tip .wrapper {
  height: calc(100% - 10px);
  background-color: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.play-type-tip .wrapper .item {
  padding: 40px 50px;
  display: flex;
  align-items: flex-start;
}

.play-type-tip .wrapper .item .van-icon {
  color: #e6c3a1;
  font-size: 60px;
}

.play-type-tip .wrapper .item .content .content-title {
  margin-top: 22px;
  font-size: 35px;
  font-weight: 500;
  color: #000;
  line-height: 0px;
}

.play-type-tip .wrapper .item .content .content-detail {
  margin-top: 5px;
  font-size: 22px;
  color: #000;
  line-height: 30px;
}

.play-type-tip .wrapper .item .content {
  flex: 1;
  margin-left: 30px;
}

.rectangle.active .wrapper {
  background-color: #ff253f !important;
}

.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
  color: #fff !important;
}

.bottom-bar .wrapper {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  padding: 20px 20px 10px 20px;
  height: 230px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10px 0 #cacaca;
  transition: transform .3s cubic-bezier(.21, 1.02, .55, 1.01);
}

.bottom-bar .wrapper.active {
  transform: translateY(-100%);
}

.bottom-bar .wrapper .item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 65px;
}

.bottom-bar .wrapper .item .label {
  font-size: 30px;
  line-height: 30px;
  color: #000;
}

.bottom-bar .wrapper .item .bet-number {
  flex: 1;
  // margin: 0 16px;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  color: #ed37ae;
  font-size: 30px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
}

.bottom-bar .wrapper .item .amount-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.van-cell {
  font-size: 30px;
  line-height: 50px;
}

.bottom-bar .wrapper .item .part {
  margin-right: 20px;
}

.bottom-bar .wrapper .item .part span {
  font-size: 30px;
  vertical-align: center;
  color: #000;
}

.bottom-bar .wrapper .item .part .number {
  margin: 0 5px;
  color: #ff253f;
  font-weight: 500;
}

::v-deep .van-field__control {
  color: #3b3597;
}

.confirm-order-modal {
  position: unset;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 20px 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 610px;
  height: 680px;
  max-height: 50%;
  z-index: 10;
  background-color: #fff;
  border-radius: 30px;
}

.confirm-order-modal .head {
  position: relative;
  height: 80px;
}

.confirm-order-modal .head .text {
  padding: 0 20px;
  height: 30px;
  line-height: 10px;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  color: #7e5678;
}

::v-deep .confirm-order-modal .van-hairline--bottom::after {
  border-bottom-width: 2px;
}

.van-popup--center {
  border-radius: 30px;
}

.confirm-order-modal .list {
  flex: 1;
  padding: 0 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.confirm-order-modal .list .lise-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.confirm-order-modal .list .lise-item .main {
  flex: 1;
  overflow: hidden;
}

.confirm-order-modal .list .lise-item .main .bet-name {
  color: #ff253f;
  font-size: 35px;
  font-weight: 500;
  line-height: 0px;
  word-wrap: break-word;
  word-break: break-all;
}

.confirm-order-modal .list .lise-item .main .detail-text {
  line-height: 0px;
  font-size: 25px;
  color: #979799;
}

.confirm-order-modal .list .lise-item {
  color: #ff253f;
}

.confirm-order-modal .sub-bar {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
}

.confirm-order-modal .sub-bar .item {
  min-width: 40%;
  height: 80px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 35px;
  font-weight: 500;
}

.confirm-order-modal .sub-bar .item.cancel-btn {
  // border: 2px solid #979799;
  color: #979799;
  background-color: #fff;
}

.confirm-order-modal .sub-bar .item.sub-btn {
  // background: linear-gradient(270deg, #e6c3a1, #7e5678);
  background: linear-gradient(20deg, #8673e5, #d958a4);
  color: #fff;
  border: 0;
}

.next-number span {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  float: right;
}

@keyframes scrollTop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, -300px, 0);
    transform: translate3d(0, -300px, 0);
  }
}

.b_list {
  height: 19rem;
  overflow: hidden;
  border: 2px solid #7d76ef;
  border-radius: 0 0 8px 8px;
}

.b_scroll {
  -webkit-animation: 10s scrollTop linear infinite normal;
  animation: 10s scrollTop linear infinite normal;
}

.b_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}
</style>
